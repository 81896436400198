// src/templates/page.jsx
import * as React from "react";
import { graphql } from "gatsby";
import { BuilderComponent } from "@builder.io/react";

import withLayout from "../../hoc/withLayout/withLayout";
import Link from "../../hooks/Link";
import { SEO } from "../../components";

const BuilderLink = props => {
  const internal = props.target !== "_blank" && /^\/(?!\/)/.test(props.href);
  if (internal) {
    return <Link activeClassName="active-link" to={props.href} {...props} />;
  }
  return <a {...props} />;
};

// Your template populates your Gatsby pages with your Builder page content.
function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.onePage?.content;

  return (
    <>
      <SEO title={page?.data.title} description={page?.data.description} />
      <BuilderComponent model="page" content={page} renderLink={BuilderLink} />
    </>
  );
}

export default withLayout(PageTemplate);

export const pageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePage(target: { urlPath: $path }) {
        content
      }
    }
  }
`;
